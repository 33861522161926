<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom"   v-if="state.layout==='mobile'">
        <ion-tab-button tab="tab1" href="/tabs/app" title="Crimp Specs">
          <ion-icon :icon="chevronBackCircle"/>
          <!--<ion-label>Tab 1</ion-label>-->
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/user" title="Your Profile">
          <ion-icon :icon="person"/>
          <!--<ion-label>Tab 2</ion-label>-->
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/tabs/favourites" title="Favorites">
          <ion-icon :icon="heart"/>
          <!--<ion-label>Tab 3</ion-label>-->
        </ion-tab-button>
        <ion-tab-button tab="tab4" @click="toggleHelp" title="Help">
          <ion-icon :icon="helpCircle" :color="state.showHelp?'primary':''"/>
          <!--<ion-label>Tab 3</ion-label>-->
        </ion-tab-button>
        <!--
        <ion-tab-button tab="tab5">
          <ion-icon :icon="trash"/>
        </ion-tab-button>-->
      </ion-tab-bar>
      <div class="mkc-copyright">&copy;2021 Kuriyama of America</div>
    </ion-tabs>
    <ion-toast
        :is-open="isHelpToastOpen"
        :message="helpToastMessage"
        :duration="1000"
        position="top"
        @didDismiss="setToastOpen(false)"
      >
      </ion-toast>
  </ion-page>
</template>

<script lang="js">
import {IonTabBar, IonTabButton, IonTabs, IonIcon, IonPage, IonToast} from '@ionic/vue';
import {defineComponent} from 'vue';
import {person, heart, triangle, trash, chevronBackCircle, helpCircle} from 'ionicons/icons';
import store from "@/store";

export default defineComponent({
  name: 'Tabs',
  components: {IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonToast},
  setup() {
    return {
      person,
      heart,
      triangle,
      trash,
      chevronBackCircle,
      helpCircle
    }
  },
  data() {
    return {
      state: store.state,
      isHelpToastOpen: false
    }
  },
  methods: {
    toggleHelp() {
      store.setShowHelp();
      this.setToastOpen(true);
    },
    setToastOpen(state) {
      this.isHelpToastOpen = state;
    }
  },
  computed: {
    helpToastMessage() {
      return (this.state.showHelp)?'Help on':'Help off'
    }
  }
});

</script>
