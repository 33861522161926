import {createRouter, createWebHistory} from '@ionic/vue-router';
//import {RouteRecordRaw} from 'vue-router';
import Tabs from '@/views/Tabs.vue'
import {auth} from './firebase.js';

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: () => import('@/components/Login.vue'),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/reset',
        component: () => import('@/components/PasswordReset.vue'),
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/tabs/',
        component: Tabs,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                redirect: '/tabs/app'
            },
            {
                path: 'app',
                component: () => import('@/components/BaseLayout.vue')
            },
            {
                path: 'user',
                component: () => import('@/views/User.vue')
            },
            {
                path: 'favourites',
                component: () => import('@/views/Favourites.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

    if (requiresAuth && !auth.currentUser) {
        next('/login')
    } else {
        next()
    }
})

export default router
