import {Plugins} from "@capacitor/core";

const {Storage} = Plugins;

/** https://www.joshmorony.com/using-the-capacitor-storage-api-for-storing-data/ **/

export async function set(key, value) {
    await Storage.set({
        key: key,
        value: JSON.stringify(value)
    });
}

export async function get(key) {
    const item = await Storage.get({key});
    return JSON.parse(item.value);
}

export async function remove(key) {
    await Storage.remove({
        key: key
    });
}
