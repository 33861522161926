import {reactive} from 'vue'
//import * as fb from './firebase.js'
import {get, set} from "@/services/storage";
import merge from 'lodash.merge';
import {db} from "./firebase.js";
import strings from "./strings.json";

const store = {
    debug: true,
    strings: strings,
    searchFields: {
        'hose-type': 'Hose Series',
        'hose-size': 'Hose Size',
        'coupling-type': 'Coupling search data',
        'hose-wall-thickness': 'Hose Wall Thickness'
    },
    categoryLabels: {
        'hose-type': 'Hose Type',
        'hose-size': 'Hose Size',
        'coupling-type': 'Coupling Type',
        'hose-wall-thickness': 'Hose Wall Thickness'

    },
    state: reactive({
        user: null,
        loginError: false,
        currentPage: 'welcome',
        layout: 'mobile',
        selectedItems: {},
        favourites: {},
        showHelp: false,
        line: false,
        category: false,
        query: false,
        filteredCollection: {
            'kurikrimp': false,
            'kuricrimp': false,
            'piranhaflex': false
        },
    }),

    setPage(page) {
        this.state.currentPage = page;
    },
    setLine(line) {
        this.state.line = line;
        this.state.category = false;
        this.state.selectedItems = {};
    },
    setCategory(category) {
        /* if (category === 'hose-type'
            && (this.state.line in this.state.selectedItems)
            && (category in this.state.selectedItems[this.state.line])) {
            //this.state.selectedItems[this.state.line] = {}
            this.state.filteredCollection[this.state.line] = false;
        } else if (category === 'hose-size'
            && (this.state.line in this.state.selectedItems)
            && (category in this.state.selectedItems[this.state.line])) {
            //this.state.selectedItems[this.state.line] = {}
            this.state.filteredCollection[this.state.line] = false;
        } */
        this.state.category = category;
    },
    setQuery(query) {
        this.state.query = query;
    },
    setCollectionData(collection, data) {
        this.state.collections[collection] = data;
    },
    setSelectedItem(collection, category, name) {
        const item = {
            [collection]: {
                [category]: name
            }
        };
        this.state.selectedItems = merge(this.state.selectedItems, item);
        this.setCategory('');
        //console.log(this.state.selectedItems)
    },
    setShowHelp() {
        this.state.showHelp = !this.state.showHelp;
        this.setSetting('showHelp', this.state.showHelp);
    },
    setUser(user) {
        this.state.user = user;
    },
    getSelectedItem(collection, category) {
        //console.log(collection, category);
        //console.log(this.state.selectedItems)
        if (collection
            && category
            && undefined !== this.state.selectedItems[collection]
            && undefined !== this.state.selectedItems[collection][category]) {
            return this.state.selectedItems[collection][category];
        }
        return false;
    },

    setSetting(key, value) {
        set(key, value);
    },
    async getSetting(key) {
        return await get(key);
    },
    setFavourite(collection, name) {
        const favouriteData = {
            'name': name,
            'customName': name
        }
        if (null === this.state.favourites
            || undefined === this.state.favourites[collection]) {
            // If object key does not exist create it
            const item = {
                [collection]: [favouriteData]
            };
            this.state.favourites = merge(this.state.favourites, item);
        } else {
            // Otherwise just add to the array
            this.state.favourites[collection].push(favouriteData);
        }
        set('favourites', this.state.favourites);
        this.setFavouritesFirestore();
    },
    setCustomSpecName(collection, name, customName) {
        const elementToUpdate = this.state.favourites[collection].findIndex(item => name === item.name);
        let tmpFavourites = [...this.state.favourites[collection]];
        tmpFavourites[elementToUpdate] = {...tmpFavourites[elementToUpdate], customName: customName};
        this.state.favourites[collection] = tmpFavourites;
        set('favourites', this.state.favourites);
        this.setFavouritesFirestore()
    },
    async removeFavourite(collection, name) {
        const collectionFavourites = this.state.favourites[collection];
        this.state.favourites[collection] = collectionFavourites.filter(item => item.name !== name);
        set('favourites', this.state.favourites);
        this.setFavouritesFirestore()
    },
    async clearFavourites() {
        this.state.favourites = null;
        set('favourites', this.state.favourites);
        this.setFavouritesFirestore()
    },
    async setFavouritesFirestore() {
        await db.collection('favourites')
            .doc(this.state.user.uid)
            .set(this.state.favourites)
            .then(() => {
                return true;
            });
    },
    async getFavouritesFirestore() {
        if (this.state.user === null) {
            return false;
        }
        const favourites = await db.collection('favourites')
            .doc(this.state.user.uid).get();
        if (!favourites.exists) {
            return false;
        } else {
            return favourites.data();
        }
    },
    async getAppSetting(key) {
        const appSetting = await db.collection('appSettings')
            .doc(key).get();
        if (!appSetting.exists) {
            return false;
        } else {
            return appSetting.data();
        }
    },
    clearParameters() {
        this.state.line = false;
        this.state.category = false;
        this.state.query = false;
        this.state.filteredCollection['kuricrimp'] = false;
        this.state.filteredCollection['kurikrimp'] = false;
        this.state.filteredCollection['piranhaflex'] = false;
        this.state.selectedItems = {};
    },
    clearParametersForCurrentLine() {
        this.state.category = false;
        this.state.query = false;
        this.state.filteredCollection[this.state.line] = false;
        this.state.selectedItems = {};
    },
    async getFilteredRecords() {
        let queryParams = [];
        Object.keys(store.searchFields).forEach((field) => {
            queryParams.push({'field': field, 'value': store.getSelectedItem(this.state.line, field)})
        })
        //console.log(this.state.line, queryParams);
        return await store.fetchFilteredRecords(this.state.line, queryParams)
    },
    async fetchFilteredRecords(collection, params = false) {
        // TODO: Add logic in here to possibly get from from local storage

        let docs = false;
        if (params !== false) {
            //console.log('filtered collection from firebase')
            docs = await this.fetchFilteredRecords_Firebase(collection, params);
        } else {
            //console.log('complete collection')
            const collectionName = store.collectionName(this.state.line, this.searchFields[this.state.category]);
            docs = await store.fetchCompleteCollection(collectionName);
        }
        if (docs) {
            //console.log(docs);
            // Need Object.freeze otherwise Vue turns it into a proxy object and then can't use .map or .length on it
            this.state.filteredCollection[collection] = Object.freeze(docs);
            //console.log(this.state.filteredCollection[collection]);
            return this.state.filteredCollection[collection];
        } else {
            //console.log(false);
            return false;
        }
    },
    async fetchFilteredRecords_Firebase(collection, params) {
        // Get filtered records from firebase
        if (!collection) return false;
        const collectionRef = db.collection(collection);

        let query = collectionRef.where(this.searchFields[params[0].field], '==', params[0].value);
        params.forEach((criteria, index) => {
            if (index > 0 && criteria.value !== false) {
                query = query.where(this.searchFields[criteria.field], '==', criteria.value);
            }
        })
        const results = await query.get();
        let docs = [];
        results.forEach(doc => {
            docs[doc.id] = doc.data();
        });

        return docs;
    },
    async fetchCompleteCollection(collection, fresh = false) {
        // Only use this for getting the lists of unique categories
        // Horribly expensive if used to get a 'main' collection!
        const localCollection = await get(collection);
        //console.log(localCollection);
        if (localCollection && !fresh) {
            // Local storage
            //console.log('getting from storage...', collection);
            return await get(collection);
        } else if (!localCollection || fresh) {
            // Firebase
            //console.log('getting from firebase...');
            const snapshot = await db.collection(collection).get();
            let docs = {}
            snapshot.forEach(doc => {
                docs[doc.id] = doc.data();
            });
            await set(collection, docs)
            return docs;
        } else {
            return false;
        }
    },
    async getFilteredRecordsByCategory(line, category) {
        //console.log(collection, category, this.state.filteredCollection[collection]);
        if (!line || !category) {
            return false;
        }
        // Filter to just unique values of specified property
        // https://www.samanthaming.com/tidbits/76-converting-object-to-array/
        const docs = [...new Set(Object.values(this.state.filteredCollection[line]).map(item => item[this.searchFields[category]]))];
        // Get it in hte same format as fetchFilteredRecords
        let results = [];
        docs.forEach((result) => {
            if (result != '') {
                results.push({'name': result});
            }
        })
        return results;
    },
    collectionName(collection, property) {
        return collection + '_' + property.replace(/ /g, '');
    },
    async searchRecords(query) {
        // NOT USED
        const snapshot = await db.collection(this.state.line)
            .orderBy(this.searchFields[this.state.category])
            .startAt(query)
            .endAt(query + '\uf8ff')
            .limit(30)
            .get();
        return snapshot.docs;
    },
    async getRecord(field, value, line) {
        // NOT USED
        const snapshot = await db.collection(line)
            .where(field, "==", value)
            .get();
        let docs = {}
        snapshot.forEach(doc => {
            docs[doc.id] = doc.data();
        });
        return docs;
    },
    async fetchUniqueRecords(collection, property) {
        // NOT USED
        return this.fetchCompleteCollection(collection).then((snapshot) => {
            return [...new Set(snapshot.map(item => item.data()[property]))];
        }).then((docs) => {
            return docs;
        });
    },
};

export default store


