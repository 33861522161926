<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script lang="js">
//import Header from '@/components/global/Header.vue'
import {IonApp, IonRouterOutlet} from '@ionic/vue';
import {defineComponent} from 'vue';
import store from './store'
import {get, set, remove} from "@/services/storage";
import firebase from 'firebase/app';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.state.user = user;
        this.checkVersion();
        this.getSettings();
        this.getFavourites();
      } else {
        this.state.user = null;
      }
    });
  },
  mounted() {
    this.state.layout = (window.innerWidth > 798) ? 'desktop' : 'mobile';
    window.addEventListener('resize', () => {
      this.state.layout = (window.innerWidth > 798) ? 'desktop' : 'mobile';
    });
  },
  data() {
    return {
      state: store.state
    }
  },
  methods: {
    async getFavourites() {
      if (this.state.user === null) {
        return false;
      }
      const firestoreFavourites = await store.getFavouritesFirestore();
      if (firestoreFavourites) {
        this.state.favourites = firestoreFavourites;
        set('favourites', firestoreFavourites);
      } else {
        const localFavourites = await get('favourites');
        this.state.favourites = localFavourites;
      }
    },
    async getSettings() {
      this.state.showHelp = await get('showHelp');
    },
    async checkVersion() {
      const masterDataVersion = await store.getAppSetting('dataVersion');
      const localDataVersion = await get('dataVersion');
      if (localDataVersion === undefined || localDataVersion === null
          || masterDataVersion.value > localDataVersion) {
        console.log('We need to update the local data');
        this.clearLocalData();
        set('dataVersion', masterDataVersion.value);
      }
    },
    clearLocalData() {
      for (const key in store.searchFields) {
        Object.keys(this.state.filteredCollection).forEach((line) => {
          const storageKey = `${line}_${store.searchFields[key].replace(/ /g, '')}`
          remove(storageKey);
          console.log(storageKey);
        })
      }
    }
  }
});
</script>
<style lang="scss">

@media screen {
  #print, .print-only {
    display: none;
  }

  ion-icon {
      pointer-events: none;
  }
}

@media print {
  .screen-only {
    display: none;
  }

  body * {
    visibility: hidden;
  }
  #print, #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .ion-item {
      display: block;
      clear: both;
    }

    ion-label {
      float: left;
      display: inline-block;
      width: 200px;
      margin: 2px;
    }

    .ion-note {
      float: left;
      display: inline-block;
      margin: 2px;
    }
  }
}
</style>
